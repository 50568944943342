import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

import PropTypes from 'prop-types';

const MerryGoRound = ({
  name,
  items,
  autoPlay = false,
  fixHeight,
  interval = false,
  ride = 'carousel',
  slide = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        {fixHeight ? (
          <div style={{ height: `${fixHeight}px` }}>{item.content}</div>
        ) : (
          <>{item.content}</>
        )}

        {item.caption && (
          <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
          />
        )}
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className={name || ''}
      ride={ride}
      autoPlay={autoPlay}
      interval={interval}
      slide={slide}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />

      {slides}

      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />

      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

MerryGoRound.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array.isRequired,
  fixHeight: PropTypes.number,
};

export default MerryGoRound;
